
import { TutorMeta } from "@/api/tutor";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MCAddModal from "@/components/classroom/MCAddModal.vue";
import MCEditModal from "@/components/classroom/MCEditModal.vue";
import { Classroom } from "@/entities/classroom";
import { getClassRoomsBasedOnRole } from "@/api/classroom";
import { Getter, State } from "@/store/helper";
import {
  convertToClassroomTableData,
  classroomsTableDefinition
} from "@/utils/table";
import { Options, Vue } from "vue-class-component";
import store from "@/store";

@Options({
  components: {
    MButton,
    MIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MCAddModal,
    MCEditModal
  },
  watch: {
    classrooms: function() {
      this.updateDatas();
    }
  }
})
export default class ClassroomList extends Vue {
  datas: { [key: string]: string | number | boolean }[] = [];
  definitions = classroomsTableDefinition;
  searchKeyword = "";
  selectedId = "";
  isDisplayedAddClassroomModal = false;
  isDisplayedEditClassroomModal = false;

  @State("classrooms") classrooms!: Classroom[];
  @State("tutors") tutors!: TutorMeta[];
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isServiceProvider") isServiceProvider!: boolean;

  get classRooms(): Classroom[] {
    return getClassRoomsBasedOnRole(
      this.isServiceProvider,
      this.classrooms,
      store.state.school!.ref.id
    );
  }

  get selectedClassroom(): Classroom | null {
    if (!this.selectedId) {
      return null;
    }

    const matchClassroom = this.classRooms.filter(
      classroom => classroom.ref.id === this.selectedId
    );
    if (matchClassroom.length === 0) {
      return null;
    }

    return matchClassroom[0];
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }

    return this.datas.filter(
      data =>
        Object.keys(data).includes("name") &&
        data.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  showAddModal() {
    this.isDisplayedAddClassroomModal = true;
  }

  closeAddModal() {
    this.isDisplayedAddClassroomModal = false;
  }

  showEditModal() {
    this.isDisplayedEditClassroomModal = true;
  }

  closeEditModal() {
    this.isDisplayedEditClassroomModal = false;
  }

  changeSelectedId(id: string) {
    this.selectedId = id;
    this.showEditModal();
  }

  created() {
    this.updateDatas();
  }

  async updateDatas() {
    if (this.classRooms.length === 0) {
      return;
    }

    this.datas = this.classRooms.map(classroom =>
      convertToClassroomTableData(classroom)
    );
  }
}
