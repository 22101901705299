import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute right-2 top-0 h-full w-6 text-center pointer-events-none flex items-center justify-center text-primary-500" }
const _hoisted_4 = {
  key: 0,
  class: "absolute left-0 pt-2 w-full py-3 rounded-md bg-white shadow-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_icon = _resolveComponent("m-icon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", {
      for: _ctx.name,
      class: "leading-7 text-sm text-gray-600"
    }, _toDisplayString(_ctx.label), 9, ["for"]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: "w-full rounded-md bg-primary-100 appearance-none py-2 pl-3 pr-10 focus:outline-none whitespace-nowrap truncate cursor-pointer transition-all duration-300 hover:text-primary-500 hover:border-primary-600",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeShowModal && _ctx.changeShowModal(...args)))
      }, _toDisplayString(_ctx.placeholder), 1),
      _createVNode("span", _hoisted_3, [
        _createVNode(_component_m_icon, { type: "chevron-down" })
      ]),
      (_ctx.showModal)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createBlock("div", {
                key: _ctx.keyOf(option),
                class: "w-full pl-3 flex items-center cursor-pointer transition-all duration-300 hover:bg-gray-100"
              }, [
                _createVNode("input", {
                  id: _ctx.keyOf(option),
                  type: "checkbox",
                  name: _ctx.name,
                  value: _ctx.valueOf(option),
                  checked: _ctx.values.includes(_ctx.valueOf(option)),
                  class: "flex-none cursor-pointer",
                  onChange: ($event: any) => (_ctx.change(option))
                }, null, 40, ["id", "name", "value", "checked", "onChange"]),
                _createVNode("label", {
                  for: _ctx.keyOf(option),
                  class: "px-3 py-2 flex-1 cursor-pointer"
                }, _toDisplayString(_ctx.textOf(option)), 9, ["for"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}