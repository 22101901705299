
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MMultiSelectBox from "@/components/form/MMultiSelectBox.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { TutorMeta } from "@/api/tutor";
import { getClassRoomsBasedOnRole, registerClassroom } from "@/api/classroom";
import { saveErrorLog } from "@/api/error";
import { Classroom } from "@/entities/classroom";
import { Getter } from "@/store/helper";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextField,
    MMultiSelectBox
  },
  emits: ["close"],
  props: {
    tutors: Array
  }
})
export default class MCAddModal extends Vue {
  name = "";
  enteredName = false;
  id = "";
  enteredId = false;
  selectTutorIds: string[] = [];
  tutors: TutorMeta[] = [];

  @Getter("isServiceProvider") isServiceProvider!: boolean;

  get tutorsExceptAdminAndOwner(): TutorMeta[] {
    return this.tutors.filter(item =>
      ["general", "employee", "supervisor"].includes(item.config.data.authority)
    );
  }

  get selectTutors(): TutorMeta[] {
    return this.tutorsExceptAdminAndOwner.filter(item =>
      this.selectTutorIds.includes(item.main.ref.id)
    );
  }

  get tutorsPlaceholder(): string {
    let text = `${this.selectTutors.length}人`;
    if (this.selectTutors.length === 0) {
      return text;
    }
    text += ": ";
    text += this.selectTutors.map(item => item.main.data.name).join(", ");
    return text;
  }

  get validName() {
    return this.name.length > 0;
  }

  get validId() {
    const classRooms: Classroom[] = getClassRoomsBasedOnRole(
      this.isServiceProvider,
      store.state.classrooms,
      store.state.school!.ref.id
    );
    if (classRooms.map(item => item.data.id).includes(this.id)) {
      return false;
    }
    return /^[0-9a-zA-Z]{2,}$/.test(this.id);
  }

  get validData() {
    return this.validName && this.validId;
  }

  get allEntered() {
    return this.enteredName && this.enteredId;
  }

  changeTutor(id: string) {
    if (this.selectTutorIds.includes(id)) {
      this.selectTutorIds = this.selectTutorIds.filter(item => item !== id);
    } else {
      this.selectTutorIds.push(id);
    }
  }

  allEnter() {
    this.enteredName = true;
    this.enteredId = true;
  }

  async register() {
    this.allEnter();

    if (!this.validData) {
      return;
    }

    store.commit("START_LOADING", "教室登録中...");

    try {
      await registerClassroom(
        this.id,
        this.name.trim(),
        this.selectTutors.map(t => t.main.ref.id)
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register classroom"
      );
      return;
    }
    store.commit("END_LOADING");
    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    const classRooms = getClassRoomsBasedOnRole(
      this.isServiceProvider,
      store.state.classrooms,
      store.state.school!.ref.id
    );
    this.id = (classRooms.length + 1).toString().padStart(2, "0");
  }
}
