
/* eslint-disable @typescript-eslint/no-explicit-any */
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  emits: ["change"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    options: Array,
    values: Array,
    valueKey: String,
    textKey: String,
    primaryKey: String
  }
})
export default class MMultiSelectBox extends Vue {
  label = "";
  name = "";
  placeholder = "";
  options = [];
  values: any[] = [];
  valueKey = "";
  textKey = "";
  primaryKey = "";
  showModal = false;

  valueOf(option: any) {
    if (this.valueKey.length === 0) {
      return option;
    }
    const keys = this.valueKey.split(".");
    let ret = option;
    for (const key of keys) {
      ret = ret[key];
    }
    return ret;
  }

  textOf(option: any) {
    if (this.textKey.length === 0) {
      return option;
    }
    const keys = this.textKey.split(".");
    let ret = option;
    for (const key of keys) {
      ret = ret[key];
    }
    return ret;
  }

  keyOf(option: any) {
    if (this.primaryKey.length === 0) {
      return option;
    }
    const keys = this.primaryKey.split(".");
    let ret = option;
    for (const key of keys) {
      ret = ret[key];
    }
    return ret;
  }

  change(option: any) {
    this.$emit("change", this.keyOf(option));
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
}
